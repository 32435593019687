<template>
  <section class="modal-container">
    <div class="modal-inner">
      <div class="modal-wrap">
        <div class="modal modal--test">
          <div class="modal-close" @click="$emit('closeTest')">
            <v-icon large :dark="windowWidth < 600">
              mdi-close
            </v-icon>
          </div>
          <div class="modal-header">
            <c-img
              src="assets/img/theme/modal-top-element.png"
              alt=""
              class="modal__top-element"
            />
            <c-img
              src="assets/img/theme/modal-bottom-element.png"
              alt=""
              class="modal__bottom-element"
            />
            <div>
              <p v-if="block !== 'test' && windowWidth > 600" class="header-title">
                {{ $t('test your knowledge') | upperFirst }}
              </p>

              <div v-if="status === 'wait'">
                <div v-if="block === 'test' && windowWidth < 600">
                  <p class="header-title__small">
                    {{ $t('test') | upperFirst }}:
                  </p>

                  <p class="header-title__small">
                    {{ testData.name }}
                  </p>
                </div>

                <h2 v-else class="header-desc">
                  {{ testData.name }}
                </h2>
              </div>

              <div v-if="status === 'success'">
                <h2 class="header-desc">
                  {{ $t('congratulations') | upperFirst }}
                </h2>
              </div>

              <div v-if="status === 'fail'">
                <h2 class="header-desc">
                  {{ $t('test failed') | upperFirst }}
                </h2>
              </div>
            </div>

            <div>
              <c-img
                src="assets/img/theme/event-logo-light.png"
                alt=""
                class="header-logo"
              />
            </div>
          </div>

          <div class="modal-wrapper">
            <Start
              v-if="block === 'start'"
              :test="testData"
              @startTest="startTest"
            />

            <Question
              v-else-if="block === 'test'"
              :questions="testData.questions"
              @finishTest="finishTest"
            />

            <Finish
              v-else-if="block === 'finish'"
              :count-right-answer="countRightAnswer"
              :status="status"
              :answers="answers"
              @finishTest="saveTestStatus"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Start from './components/Start'
import Question from './components/Question'
import Finish from './components/Finish'

export default {
  components: { Start, Question, Finish },

  props: {
    testData: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      block: 'test',
      countRightAnswer: 0,
      status: 'wait',
      windowWidth: null,
      answers: null
    }
  },

  mounted () {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },

  updated () {
    this.onResize()
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    startTest () {
      this.block = 'test'
    },

    finishTest (answers) {
      this.block = 'finish'
      this.answers = answers

      answers.forEach(el => {
        if (el) {
          this.countRightAnswer += 1
        }
      })

      answers.every(el => el) ? this.status = 'success' : this.status = 'fail'
    },

    saveTestStatus () {
      this.$emit('finishTest', { status: this.status, test: this.testData })
    },

    nextTest () {
      this.countRightAnswer = 0
      this.block = 'test'
    },

    onResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style scoped lang="scss">
.modal-container {
  position: fixed;
  z-index: 100;
  background-color: rgba(#000, 0.48);
  height: 100vh;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  animation: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: relative;
  width: 100%;
  max-width: 720px;
  //margin: 200px auto 0;
  animation: opacity 0.3s;

  &-header {
    padding: 27px 50px;

    .header-title {
      font-size: 21px;
      line-height: 30px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #fff;
      opacity: 0.8;
      margin-bottom: 12px;
    }

    .header-desc {
      font-style: italic;
      font-weight: bold;
      font-size: 40px;
      line-height: 42px;
      text-transform: uppercase;
      color: #fff;
    }

    .header-logo {
      width: 118px;
    }
  }

  .header-title__small {
    font-size: 21px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #fff;
    opacity: 0.6;
    text-transform: uppercase;
    margin: 0;
  }

  &-wrapper {
    background-color: #fff;
    border-radius: 0 0 20px 20px;
  }
}

@media screen and (max-width: 600px) {
  .modal-container {
    padding: 10px;
    overflow: auto;
  }

  .modal-inner {
    max-height: 100%;
  }

  .modal-wrap {
    padding-bottom: 10px;
  }

  .modal {
    margin: 0;
    height: auto;
    max-height: 100%;

    &-header {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      padding: 21px 21px 33px;

      .header-title {
        opacity: 0.6;
        margin-bottom: 10px;
      }

      .header-desc {
        font-size: 28px;
        line-height: 38px;
      }

      .header-logo {
        width: 95px;
        margin-bottom: 32px;
      }

      // .modal__top-element {
      //   left: -20px;
      //   top: 230px;
      //   z-index: 1;
      //   width: 40px;
      //   height: 40px;
      // }
    }

    &-close {
      width: 21px;
      height: 21px;
      background-color: transparent;
      top: 21px;
      right: 21px;

      .close-path {
        stroke: #fff;
      }
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
