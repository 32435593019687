<template>
  <section
    :class="[
      'banner',
      { 'banner--image-right': imagePosition === 'right' }
    ]"
    :style="{backgroundImage: `url(${backgroundImage})`}"
  >
    <!-- eslint-disable-next-line -->
    <div
      v-if="data.image"
      class="banner__image"
      :style="{backgroundImage: `url(${image})`}"
    >
    </div>
    <v-container class="banner__container">
      <div class="banner__content">
        <div class="banner__pretitle">
          {{ data.pretitle }}
        </div>
        <div class="banner__title">
          {{ data.title }}
        </div>
        <!-- eslint-disable-next-line -->
        <div class="banner__subtitle" v-html="data.subtitle">
        </div>
        <a
          :href="data.button.link"
          target="_blank"
          class="banner__button"
        >
          {{ data.button.label }}
        </a>
      </div>
    </v-container>
  </section>
</template>

<script>
import { getLinkByEvent } from '@/utils/mediaHelpers'

export default {
  name: 'Banner',

  props: {
    imagePosition: {
      type: String,
      default: () => 'left'
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    image () {
      return getLinkByEvent(`assets/img/theme/${this.data.image}`)
    },

    backgroundImage () {
      return getLinkByEvent(`assets/img/theme/${this.data.backgroundImage}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/mixins/breakpoints";

.banner {
  border-radius: 20px;
  background-position: 50% 0;
  background-size: cover;
  background-repeat: repeat-x;
  overflow: hidden;
  margin: 8px 0;
  position: relative;
}

.banner__container {
  position: relative;
  text-align: right;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }

  .banner--image-right & {
    text-align: left;
  }
}

.banner__content {
  display: inline-block;
  vertical-align: top;
  padding: 70px 30px 90px;
  width: 100%;
  text-align: left;
  max-width: 580px;

  @media screen and (max-width: 1262px) {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    padding: 0 30px 30px;
    width: 100%;
    margin-top: -80px;
  }
}

.banner__image {
  background-position: center center;
  background-repeat: no-repeat;
  // background-size: contain;
  width: 54%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  .banner--image-right & {
    left: auto;
    right: 0;
  }

  @media screen and (max-width: 992px) {
    background-size: contain;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    left: auto;
    top: auto;
    bottom: auto;
  }
}

.banner__pretitle {
  font-family: var(--main-primary-font);
  font-weight: normal;
  font-size: 1rem;
  color: #fff;
}

.banner__title {
  color: #fff;
  text-transform: uppercase;
  font: var(--main-primary-font);
  font-weight: 800;
  letter-spacing: 1px;
  font-size: 96px;

  @media screen and (max-width: 1262px) {
    font-size: 80px;
  }

  @media screen and (max-width: 992px) {
    font-size: 60px;
  }

  @media screen and (max-width: 768px) {
    font-size: 50px;
  }
}

.banner__subtitle {
  color: var(--main-primary-background-color);
  font: var(--main-primary-font);
  line-height: 1;
  font-size: 36px;
  font-weight: 500;

  @media screen and (max-width: 1262px) {
    font-size: 28px;
  }

  @media screen and (max-width: 992px) {
    font-size: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    margin-top: 10px;
    line-height: 1.2;
    max-width: 280px;
    font-weight: 600;
  }
}

.banner__button {
  background-color: #fff;
  color: var(--main-primary-background-color);
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  min-width: 240px;
  margin-top: 120px;
  text-align: center;
  padding: 15px 30px;
  border-radius: 10px;
  transition: 0.2s ease-in-out;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 30px -10px rgba(#000, 0.05);
  }

  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
}
</style>
