<template>
  <section class="finish">
    <h3 class="finish__title">
      {{ $t('your result') }} - {{ countRightAnswer }} / {{ answers.length }}
    </h3>
    <p v-if="status === 'success'" class="finish__desc">
      {{ $t('test success') }}
    </p>
    <p v-else class="finish__desc">
      {{ $t('test fail') }}
    </p>
    <div
      class="next-btn"
      @click="finishTest"
    >
      {{ $t('finish test') }}
    </div>
  </section>
</template>

<script>
export default {
  name: 'Finish',

  props: {
    answers: {
      type: Array,
      default: () => []
    },
    status: {
      type: String,
      default: ''
    },
    countRightAnswer: {
      type: Number,
      default: 0
    }
  },

  methods: {
    finishTest () {
      this.$emit('finishTest')
    }
  }
}
</script>

<style scoped lang="scss">
.finish {
  padding: 44px 0 43px 49px;

  &__title {
    font-style: italic;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    text-transform: uppercase;
    margin-bottom: 25px;
  }

  &__desc {
    opacity: 0.8;
    max-width: 340px;
  }
}

.next-btn {
  width: 260px;
  height: 60px;
  padding: 0 30px 0 40px;
  border-radius: 15px;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 120px;
  cursor: pointer;
  background: linear-gradient(45deg, var(--main-primary-background-color), var(--main-secondary-background-color));
  // background: var(--test-button) no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  .finish {
    padding: 44px 20px 20px;

    &__desc {
      max-width: 100%;
    }
  }

  .next-btn {
    width: 100%;
    padding: 0 30px 0 40px;
  }
}
</style>
