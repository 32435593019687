<template>
  <section class="information">
    <v-container class="information__container">
      <v-row>
        <v-col>
          <!-- eslint-disable-next-line -->
          <div class="information__text">{{ $t('information_text') }}</div>
        </v-col>
        <v-col cols="7">
          <!-- eslint-disable-next-line -->
          <div class="information__slogan information__slogan--1">{{ $t('information_slogan_1') }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- eslint-disable-next-line -->
          <div class="information__slogan information__slogan--second">{{ $t('information_slogan_2') }}</div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Information'
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/mixins/breakpoints";

.information__container {
  background-image: var(--information-bg);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  border-bottom: 1px solid rgba(#252525, 0.3);

  @media screen and (max-width: 1600px) {
    background-size: 600px auto;
  }

  @include media-breakpoint-down(md) {
    background-size: 400px auto;
  }

  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 180px;
    padding-top: 50px;
    background-position: 50% 100%;

    .row {
      display: block;
    }

    .col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 460px) {
    background-size: contain;
  }
}

.information__text {
  max-width: 360px;

  @media screen and (max-width: 992px) {
    max-width: 100%;
  }
}

.information__slogan {
  color: var(--main-primary-background-color);
  font: var(--main-primary-font);
  line-height: 1;
  max-width: 820px;
  font-size: 72px;
  // white-space: pre;
  text-align: right;

  @media screen and (max-width: 1600px) {
    font-size: 54px;
  }

  @media screen and (max-width: 992px) {
    text-align: left;
  }

  @include media-breakpoint-down(md) {
    font-size: 44px;
  }

  @media screen and (max-width: 576px) {
    font-size: 36px;
  }

  @media screen and (max-width: 460px) {
    font-size: 28px;
  }

  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
}

.information__slogan--second {
  color: var(--main-secondary-background-color);
  max-width: 720px;
  line-height: 1.2;
  font-size: 56px;
  text-align: left;

  @media screen and (max-width: 1600px) {
    font-size: 50px;
  }

  @include media-breakpoint-down(md) {
    font-size: 40px;
  }

  @media screen and (max-width: 576px) {
    font-size: 30px;
  }

  @media screen and (max-width: 460px) {
    font-size: 28px;
  }

  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
}
</style>
