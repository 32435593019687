<template>
  <section class="section-mp-rank">
    <div class="section-mp-rank__text container">
      <span>
        Ocean <br>
        of discoveries
      </span>
    </div>

    <v-container>
      <div class="mp-rank-top-block">
        <div class="mp-rank-top-left">
          <div class="sect-count">
            .05
          </div>

          <router-link
            to="/nominations"
          >
            <h2 style="max-width: 340px;" class="section-title">
              {{ $t('new ranks') | upperFirst }}
            </h2>
          </router-link>
        </div>

        <div class="mp-rank-top-right">
          <div class="mp-rank-period">
            {{ $t('from june 20\' till jan 21\'') }}
          </div>
        </div>
      </div>
    </v-container>

    <v-lazy
      :options="{
        threshold: .3
      }"
      transition="fade-transition"
    >
      <div class="mp-rank-items-block">
        <div ref="mprank" :class="{isMobile}">
          <template
            v-for="(ranks, key) in ranksList"
            :ranks="ranks"
          >
            <div :key="key" class="mp-rank-items-row">
              <template
                v-for="(rank, rankKey) in ranks"
                :rank="rank"
              >
                <div
                  :key="rankKey"
                  class="mp-rank-item"
                >
                  <router-link
                    v-if="rank.type === 'rank'"
                    :to="rank.linkUrl"
                  >
                    <div :class="['mp-rank-item__content', rank.color]">
                      <div class="mp-rank-item__count">
                        {{ rank.count }}
                      </div>

                      <div class="mp-rank-item__text">
                        {{ $tc(rank.title, rank.count) | upperFirst }}
                      </div>
                    </div>
                  </router-link>

                  <router-link
                    v-else-if="rank.type === 'image'"
                    :to="rank.linkUrl"
                  >
                    <c-img
                      class="mp-rank-item__img"
                      :src="rank.imageUrl"
                      alt=""
                    />
                  </router-link>

                  <router-link
                    v-else
                    :to="rank.linkUrl"
                    class="mp-rank-item"
                  />
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </v-lazy>

    <div class="mp-rank-all-link-block-mobile-wrapper">
      <div class="mp-rank-all-link-block">
        <router-link to="/nominations" class="mp-rank-all-link">
          {{ $t('all nominees') | upperFirst }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  props: {
    items: {
      type: [Object, Array],
      default: () => ({})
    }
  },
  computed: {
    ranksList () {
      return [
        [
          {
            type: 'all',
            linkUrl: '/nominations'
          },
          {
            type: 'rank',
            title: this.items.square_1_rank,
            linkUrl: this.anchorFromRankName('platinum executive'),
            count: this.items.square_1_content,
            color: 'rank--purple'
          },
          {
            type: 'image',
            imageUrl: 'assets/img/mainpage/newranks/mp-rank-item__img-1.jpg',
            linkUrl: '/nominations',
            alt: 'img'
          },
          {
            type: 'rank',
            title: this.items.square_2_rank,
            linkUrl: this.anchorFromRankName('gold executive'),
            count: this.items.square_2_content,
            color: 'rank--dark-purple'
          },
          {
            type: 'image',
            imageUrl: 'assets/img/mainpage/newranks/mp-rank-item__img-2.jpg',
            linkUrl: '/nominations',
            alt: 'img'
          }
        ],
        [
          {
            type: 'image',
            imageUrl: 'assets/img/mainpage/newranks/mp-rank-item__img-3.jpg',
            linkUrl: '/nominations',
            alt: 'img'
          },
          {
            type: 'rank',
            title: this.items.square_3_rank,
            linkUrl: this.anchorFromRankName('double silver executive'),
            count: this.items.square_3_content,
            color: 'rank--blue'
          },
          {
            type: 'image',
            imageUrl: 'assets/img/mainpage/newranks/mp-rank-item__img-4.jpg',
            linkUrl: '/nominations',
            alt: 'img'
          },
          {
            type: 'rank',
            title: this.items.square_4_rank,
            linkUrl: this.anchorFromRankName('silver executive'),
            count: this.items.square_4_content,
            color: 'rank--dark-blue'
          }
        ],
        [
          {
            type: 'image',
            imageUrl: 'assets/img/mainpage/newranks/mp-rank-item__img-5.jpg',
            linkUrl: '/nominations',
            alt: 'img'
          },
          {
            type: 'rank',
            title: this.items.square_5_rank,
            linkUrl: this.anchorFromRankName('20k executive'),
            count: this.items.square_5_content,
            color: 'rank--dark-purple'
          },
          {
            type: 'rank',
            title: this.items.square_6_rank,
            linkUrl: this.anchorFromRankName('executive'),
            count: this.items.square_6_content,
            color: 'rank--cyan'
          },
          {
            type: 'image',
            imageUrl: 'assets/img/mainpage/newranks/mp-rank-item__img-6.jpg',
            linkUrl: '/nominations',
            alt: 'img'
          },
          {
            type: 'all',
            linkUrl: '/nominations'
          }
        ],
        [{
          type: 'rank',
          title: this.items.square_7_rank,
          linkUrl: this.anchorFromRankName('diamond director'),
          count: this.items.square_7_content,
          color: 'rank--cyan'
        },
        {
          type: 'image',
          imageUrl: 'assets/img/mainpage/newranks/mp-rank-item__img-7.jpg',
          linkUrl: '/nominations',
          alt: 'img'
        },
        // {
        //   type: 'image',
        //   imageUrl: 'assets/img/mainpage/newranks/mp-rank-item__img-8.jpg',
        //   linkUrl: '/nominations',
        //   alt: 'img'
        // },
        {
          type: 'rank',
          title: this.items.square_8_rank,
          linkUrl: this.anchorFromRankName('emerald director'),
          count: this.items.square_8_content,
          color: 'rank--blue'
        },
        {
          type: 'rank',
          title: this.items.square_9_rank,
          linkUrl: this.anchorFromRankName('gold director'),
          count: this.items.square_9_content,
          color: 'rank--purple'
        }
        ]
      ]
    }
  },
  methods: {
    isMobile () {
      return screen.width <= 760 ? 'mp-rank-items d-block d-lg-none' : 'mp-rank-items d-none d-lg-block'
    },

    anchorFromRankName (rankName) {
      if (!rankName) {
        return {
          name: 'nominations'
        }
      }
      return {
        name: 'nominations',
        hash: `#${rankName.split(' ').join('_').split('-').join('_')}`
      }
    }
  }
}
</script>
