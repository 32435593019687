export const EVENTS_STATIC_DATA = [
  {
    id: 'caec76fb-2f2f-4625-85f3-3845666c22c1',
    dateOfThe: '18-20 июля 2021 года',
    banner1: {
      pretitle: 'Новинка Coral Club',
      title: 'Oceanmin',
      subtitle: 'Сила океана <br> в твоем стакане',
      image: 'banner1-image.png',
      backgroundImage: 'banner1-bg.png',
      button: {
        label: 'Купить',
        link: '#'
      }
    },
    banner2: {
      pretitle: 'Новинка Coral Club',
      title: 'CoralBrite',
      subtitle: 'Твоя настоящая <br>улыбка',
      backgroundImage: 'banner2-bg.jpg',
      button: {
        label: 'Купить',
        link: '#'
      }
    }
  },
  {
    id: '68b790f4-f645-45f3-84cd-27761a50793b',
    dateOfThe: '13-14 февраля 2021 года'
  }
]
